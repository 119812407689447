import Cookies from 'js-cookie';
import Commercial from './commercial';

class Cmp {

	constructor(options) {
		let defaults = {};
		this.params = Object.assign({}, defaults, options || {});
		this.start();
	}

	start() {



		// If CMP is not loaded check again after 1 second
		if (typeof __tcfapi === 'undefined') {

			window.setTimeout(() => {
				this.start();
			}, 1000);

			return;
		}

		var self = this;

		// Check consent
		__tcfapi('addEventListener', 2, function (tcData, success) {

			if (!success) {
				return;
			}

			if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {

				var consents = tcData.purpose.consents;
				var legitimateInterests = tcData.purpose.legitimateInterests;

				/*
				1: Store and/or access information on a device
				2: Select basic ads
				3: Create a personalised ads profile
				4: Select personalised ads
				5: Create a personalised content profile
				6: Select personalised content
				7: Measure ad performance
				8: Measure content performance
				9: Apply market research to generate audience insights
				10: Develop and improve products
				*/

				var can_1 = consents['1'] ? 1 : 0;
				var can_2 = consents['2'] ? 1 : 0;
				var can_3 = consents['3'] ? 1 : 0;
				var can_4 = consents['4'] ? 1 : 0;
				var can_5 = consents['5'] ? 1 : 0;
				var can_6 = consents['6'] ? 1 : 0;
				var can_7 = consents['7'] ? 1 : 0;
				var can_8 = consents['8'] ? 1 : 0;
				var can_9 = consents['9'] ? 1 : 0;
				var can_10 = consents['10'] ? 1 : 0;

				var may_1 = legitimateInterests['1'] ? 1 : 0;
				var may_2 = legitimateInterests['2'] ? 1 : 0;
				var may_3 = legitimateInterests['3'] ? 1 : 0;
				var may_4 = legitimateInterests['4'] ? 1 : 0;
				var may_5 = legitimateInterests['5'] ? 1 : 0;
				var may_6 = legitimateInterests['6'] ? 1 : 0;
				var may_7 = legitimateInterests['7'] ? 1 : 0;
				var may_8 = legitimateInterests['8'] ? 1 : 0;
				var may_9 = legitimateInterests['9'] ? 1 : 0;
				var may_10 = legitimateInterests['10'] ? 1 : 0;

				// Store and/or access information on a device (Purpose 1)
				if (!can_1) {
					return;
				}

				// Google Advertising Products
				// Action = create a new commercial.js module
				if (tcData.vendor.consents['755'] && tcData.vendor.legitimateInterests['755']) {
					if ((can_2 || may_2) && (can_7 || may_7) && (can_9 || may_9) && (can_10 || may_10)) {
						let personalized = can_3 && can_4;
						self.loadGoogleVendor(personalized);
					}
				}

				// Neustar for P&G Vendor id: 577
        // Action = Declare function to window & load vendor script
        /*
				if (tcData.vendor.consents['577']) {
					if ((can_2 || may_2) && (can_3 || may_3) && (can_4 || may_4) && (can_5 || may_5) &&
						(can_6 || may_6) && (can_7 || may_7) && (can_8 || may_8) && (can_9 || may_9)) {
						self.loadPnGVendor(tcData);
					}
        }
        */

				// Semasio for Tempo id: 84
        // Action = Load vendor pixel script
        /*
				if (tcData.vendor.consents['84']) {
					if (can_3 && can_9 && can_10) {
						self.loadTempoVendor(tcData);
					}
        }
        */
        
        // Unilever Vendor id: 829
        // Action = load vendor script
        /*
        if (tcData.vendor.consents['829']) {
          if ((can_2 || may_2) && can_3 && can_4 && can_5 && can_6 && (can_7 || may_7) &&
            (can_8 || may_8) && can_9) {
              self.loadUnileverVendor();
          }
        }
        */
    
			}
		});
	}

	loadGoogleVendor(personalized) {

		new Commercial({
			loadScripts: true,
			personalized: personalized
		});
	}

	loadPnGVendor(tcData) {
		var GeoGdprApplies = tcData.gdprApplies ? 1 : 0;
		window.setUpAgknTag = function (tag) {
			tag.setBpId("dpggreece");
			if (typeof window.dfp_targeting.section !== 'undefined') {
				tag.setCat(window.dfp_targeting.section);
			}
			if (typeof window.dfp_targeting.category !== 'undefined') {
				tag.addEchoKeyValue("subcat", window.dfp_targeting.category);
			}
			tag.setGdpr(GeoGdprApplies);
			tag.setGdprConsent(tcData.tcString);
		}

		// P&G
		const png = document.createElement('script');
		png.src = "https://js.agkn.com/prod/v0/tag.js";
		png.async = true;
		document.head.appendChild(png);
	}

	loadTempoVendor(tcData) {
		try {
			var GeoGdprApplies = tcData.gdprApplies ? 1 : 0;
			var smoGDPRFlag = GeoGdprApplies;
			var smoGDPRConsentString = tcData.tcString;
			var smoRand = Math.random() * 100000000000000000;
			var smoTrSeq = (typeof (smoTrSeq) != "undefined") ? smoTrSeq + 1 : 0;
			var smoPrtKey = 'omdgr/1';
			var smoPUrl = escape(window != parent ? document.referrer : document.location.href);
			var smoIframe = window != parent ? '&nIframe=1' : '';
			var smoParams = '&sType=view&nCampaignId=1';
			var smoProto = (document.location.protocol == 'https:') ? "https:" : "http:";
			var smoTrUrl = smoProto + '//uip.semasio.net/' + smoPrtKey + '/info?gdpr=' + smoGDPRFlag + '&gdpr_consent=' + smoGDPRConsentString + '&_referrer=' + smoPUrl + smoParams + '&nJs=1&_ignore=' + smoRand + smoIframe;
			var smoTrImg = new Image(); smoTrImg.src = smoTrUrl;
		} catch (err) { }
  }
  
  loadUnileverVendor() {
		const unilever = document.createElement('script');
		unilever.src = "https://cdn.cnngreece.gr/services/vendors/unilever-dil-v1.js";
		unilever.async = true;
		document.head.appendChild(unilever);
	}

	repropmpt() {
		Cookies.remove('euconsent-v2', {
			path: '/',
			domain: '.' + location.host
		});

		__tcfapi('displayConsentUi', 2, function () { });
	}
}

export {
	Cmp as
		default
};
