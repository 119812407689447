import {
  debounce
} from 'debounce';

class Commercial {
  constructor(options) {

    var defaults = {
      param: '',
      breakpoints: [
        300,
        768,
        1024
      ],
      devices: [
        'mobile',
        'tablet',
        'desktop'
      ],
      personalized: true,
      loadScripts: false
    };

    this.params = Object.assign({}, defaults, options || {});

    this.previousViewport = null;
    this.currentViewport = null;
    this.firstLoad = true;

    this.adElements = document.querySelectorAll('.js-ad-slot');

    if (!this.params.personalized) {
      googletag.pubads().setRequestNonPersonalizedAds(1);
    }

    if (this.params.loadScripts) {
      
      const ocm = document.createElement('script');
      ocm.src = "https://cdn.orangeclickmedia.com/tech/fpress.gr/ocm.js";
      ocm.async = true;
      document.head.appendChild(ocm);
      
      const gpt = document.createElement('script');
      gpt.src = "https://www.googletagservices.com/tag/js/gpt.js";
      gpt.async = true;
      document.head.appendChild(gpt);

      /*
      (function(doc) {
        var xtb = document.createElement("script");
        xtb.type = "text/javascript";
        xtb.async = true;
        xtb.src = "https://app.exitbee.com/c/3215/exitbee.js";
        document.getElementsByTagName("head")[0].appendChild(xtb);
      }())
      */
    }

    this.setViewport();
    this.showAds();
    this.addEvents();
  }

  addEvents() {
    window.addEventListener('resize', debounce((event) => {

      this.firstLoad = false;

      this.setViewport();

      if (this.adSlots.length && this.previousViewport != this.currentViewport) {
        googletag.destroySlots(this.adSlots);

        this.showAds();

        this.previousViewport = this.currentViewport;
      }
    }, 500));
  }

  showAds() {

    this.adSlots = [];

    var elementsTotal = this.adElements.length;

    for (var index = 0; index < elementsTotal; index++) {

      var el = this.adElements[index];
      var adSlot = JSON.parse(el.getAttribute('data-params'));

      if (adSlot.devices.indexOf(this.currentViewport) > -1) {
        this.renderAdSlot(adSlot);
        // console.log(adSlot.name, adSlot.position);
      }

    }
  }

  renderAdSlot(adSlot) {

    googletag.cmd.push(() => {

      var mapping = null;
      var sizeMappingsTotal = adSlot.size_mapping.length;
      if (sizeMappingsTotal) {
        var m = googletag.sizeMapping();
        for (var index = 0; index < sizeMappingsTotal; index++) {
          var size_mapping_array = JSON.parse('[' + adSlot.size_mapping[index] + ']');
          m.addSize(size_mapping_array[0], size_mapping_array[1]);
        }
        m.addSize([0, 0], []).build();
        var mapping = m.build();
      }

      var slot = googletag.defineSlot('/' + adSlot.publisher + '/' + adSlot.slot, adSlot.sizes, adSlot.position);

      if (!slot) {
        return;
      }

      if (mapping) {
        slot.defineSizeMapping(mapping);
      }

      slot.addService(googletag.pubads());
      googletag.display(adSlot.position);

      this.adSlots.push(slot);

    });
  }

  destroyAd() {
    if (typeof googletag.pubads !== 'function') {
      return;
    }
    if (window.newsGalleryInlineAdSlot) {
      googletag.destroySlots([window.newsGalleryInlineAdSlot]);
    }
  }

  setViewport() {

    var windowWidth = this.getWindowWidth();

    if (this.firstLoad == false) {
      this.previousViewport = this.currentViewport;
    }

    for (var i = 0; i < this.params.breakpoints.length; i++) {
      if (windowWidth >= this.params.breakpoints[i]) {

        this.currentViewport = this.params.devices[i];
      }
    }

    if (this.firstLoad == true) {
      this.previousViewport = this.currentViewport;
    }

  }

  getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth;
  }

}

export {
  Commercial as
  default
};
